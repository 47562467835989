import React from 'react'

import Button from '../button'

import styles from './style.module.scss'

export default () => {
    return (
        <div className={styles.container}>
            <div className={styles.gettingStartedContainer}>
                <div className={styles.gettingStartedHeader}>
                    <h3 className={styles.gettingStartedTitle}>
                        Getting started
                    </h3>
                </div>
                <div className={styles.gettingStartedBody}>
                    <div className={styles.gettingStartedHello}>
                        Hello Walid,
                    </div>
                    <p className={styles.gettingStartedDescription}>
                        Here are few things you can do with PEP
                    </p>
                    <div className={styles.gettingStartedItem}>
                        <span>Add your first property</span>
                        <Button
                            className={styles.startButton}
                            link="/owner-property"
                        >
                            Start
                        </Button>
                    </div>
                    <div className={styles.gettingStartedItem}>
                        <span>Review your offers</span>
                        <Button className={styles.startButton}>Start</Button>
                    </div>
                </div>
            </div>
            <div className={styles.statsContainer}>
                <div className={styles.stats}>
                    <h3 className={styles.statsTitle}>Total Properties</h3>
                    <div className={styles.statsValue}>100</div>
                </div>
                <div className={styles.stats}>
                    <h3 className={styles.statsTitle}>Approved Properties</h3>
                    <div className={styles.statsValue}>100</div>
                </div>
                <div className={styles.stats}>
                    <h3 className={styles.statsTitle}>Archived Properties</h3>
                    <div className={styles.statsValue}>100</div>
                </div>
                <div className={styles.stats}>
                    <h3 className={styles.statsTitle}>Offers</h3>
                    <div className={styles.statsValue}>100</div>
                </div>
            </div>
        </div>
    )
}
