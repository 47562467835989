import React from 'react'

import SEO from '../components/seo'

import Layout from '../components/layout'

import Dashboard from '../components/customer-dashboard'

import ProtectedPage from '../components/protected-page'

import DashboardSidebar, {
    SidebarWrapper,
    SidebarProvider
} from '../components/dashboard-sidebar'

export default () => {
    return (
        <SidebarProvider>
            <Layout noFooter noSocialBar noScrollTop noHeader dashboardHeader>
                <SEO title="Dashboard" description={'Account dashbaord'} />
                <ProtectedPage>
                    <SidebarWrapper>
                        <DashboardSidebar />
                        <Dashboard />
                    </SidebarWrapper>
                </ProtectedPage>
            </Layout>
        </SidebarProvider>
    )
}
